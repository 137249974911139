import React, {useState, useRef} from 'react';
import {YMaps, Map as YandexMap, Placemark, Clusterer} from 'react-yandex-maps';
import icon from '../static/icons/map_point.svg';
import iconIn from '../static/icons/map_point_inner.svg';
import {YANDEX_MAPS_API_KEY} from "../const";

const points = [
    {
        lat: 59.990928,
        lng: 30.206129,
        name: 'ТРК Меркурий',
        link: '/restaurants/merkuriy',
        address: 'ул Савушкина, д 141 литера А',
        time: 'Ежедневно: 10:00 - 22:00',
        phone: '+7 (981) 024-21-51'
    },
    {
        lat: 60.004892,
        lng: 30.299811,
        name: 'ТРК Сити Молл',
        link: '/restaurants/city-mall',
        address: 'Коломяжский пр-кт, д 17 к 2',
        time: 'Ежедневно: 10:00 - 22:00',
        phone: '+7 (906) 253-62-10'
    },
    {
        lat: 60.033502,
        lng: 30.366165,
        name: 'ТРК Родео Драйв',
        link: '/restaurants/rodeo-draiv',
        address: 'пр-кт Культуры, д 1',
        time: 'Ежедневно: 10:00 - 22:00',
        phone: '+7 (999) 248-95-62',
    },
    {
        lat: 59.945076,
        lng: 30.284436,
        name: 'Василеостровская',
        link: '/restaurants/vasilyevskiy',
        address: 'пр-кт Средний В.О., д 11',
        time: 'Ежедневно: 11:00 - 23:00',
        phone: '+7 (981) 826-76-78',
    },
    {
        lat: 59.930187,
        lng: 30.366479,
        name: 'Невский',
        link: '/restaurants/nevskiy',
        address: 'Невский пр-кт, д 95',
        time: 'Ежедневно: 11:00 - 23:00',
        phone: '+7 (911) 906-07-76',
    },
    {
        lat: 59.924145,
        lng: 30.356211,
        name: 'Лиговский',
        link: '/restaurants/ligovskiy',
        address: 'Лиговский пр-кт, д 87 литера А',
        phone: '+7 (965) 010-19-61',
        time: 'Ежедневно: 11:00 - 23:00',
    },
    {
        lat: 59.819909,
        lng: 30.316982,
        name: 'ТРК Лето',
        link: '/restaurants/leto',
        address: 'Пулковское шоссе, д 25 к 1 литера А',
        time: 'Ежедневно: 10:00 - 22:00',
        phone: '+7 (966) 929-82-02',
    }
];

export const Map = ({height}) => {
    const [active, setActive] = useState(null);

    const closeInfoWindow = () => {
        setActive(null);
    };

    const openInfoWindow = (id) => {
        setActive(id);
    };

    const mapRef = useRef(null); // Ссылка на экземпляр карты

    const handlePlacemarkClick = (coordinates, content) => {
        if (mapRef.current) {
            mapRef.current.balloon.open(coordinates, {
                content
            });
        }
    };

    return (
        <YMaps>
            <div style={{height: height || "600px"}}>
                <YandexMap
                    instanceRef={(ref) => (mapRef.current = ref)}
                    defaultState={{
                        center: [59.9280011, 30.3378048],
                        zoom: 14,
                        controls: []
                    }}
                    modules={['control.ZoomControl']}
                    width="100%"
                    height="100%"
                >
                    <Clusterer
                        options={{
                            preset: 'islands#invertedVioletClusterIcons',
                            groupByCoordinates: false
                        }}
                    >
                        {points.map((el) => (
                            <Placemark
                                key={el.name}
                                geometry={[el.lat, el.lng]}
                                properties={{
                                    hintContent: el.name
                                }}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: icon,
                                    iconImageSize: [80, 80],
                                    iconImageOffset: [-20, -40]
                                }}
                                onClick={() =>
                                    handlePlacemarkClick(
                                        [el.lat, el.lng],
                                        `
                                            <div class="marker-window">
                                                <div>
                                                    <img src="${iconIn}" alt=""/>
                                                </div>
                                                <div>
                                                    <strong>${el.name}</strong>
                                                    <p>${el.address}</p>
                                                    <p>${el.phone}</p>
                                                    <p>График работы: ${el.time}</p>
                                                    <a href="${el.link}">Посмотреть меню ресторана</a>
                                                </div>
                                            </div>
                                        `
                                    )
                                }
                            />
                        ))}
                    </Clusterer>
                </YandexMap>
            </div>
        </YMaps>
    );
};

export const MapLanding = ({height, point}) => {
    return (
        <YMaps query={{apikey: YANDEX_MAPS_API_KEY}}>
            <div style={{height: height || "600px"}}>
                <YandexMap
                    defaultState={{
                        center: [point.lat, point.lng],
                        zoom: 14,
                        controls: []
                    }}
                    modules={['control.ZoomControl']}
                    width="100%"
                    height="100%"
                >
                    <Placemark
                        geometry={[point.lat, point.lng]}
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref: icon,
                            iconImageSize: [40, 40],
                            iconImageOffset: [-20, -40]
                        }}
                    />
                </YandexMap>
            </div>
        </YMaps>
    );
};
